



































































































import { Component } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import DangerTable from '@/views/setting/components/dangerTable.vue'
import VulnTable from '@/views/setting/components/vulnTable.vue'
import HookTable from '@/views/setting/components/hookTable.vue'
@Component({
  name: 'HookRule',
  components: {
    DangerTable,
    HookTable,
    VulnTable,
  },
})
export default class ChangePassword extends VueBase {
  activeLanguage = 0
  activeLanguageName = ''
  languageOptions = []
  reflash = false
  changeLanguage(item: any) {
    this.activeLanguage = item.id
    this.activeLanguageName = item.name
    this.reflash = false
    this.$nextTick(() => {
      this.getBase()
      this.reflash = true
    })
  }
  type = '2'
  base = {
    ruleCount: 0,
    typeCount: 0,
    sinkCount: 0,
  }
  async getBase() {
    this.loadingStart()
    const { status, msg, data } = await this.services.setting.hookRuleSummary({
      language_id: this.activeLanguage,
    })
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.base = data
  }
  async getProgramLanguage() {
    this.loadingStart()
    const { status, msg, data } = await this.services.setting.programLanguage()
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.languageOptions = data
    this.activeLanguage = data[0].id
    this.activeLanguageName = data[0].name
    this.type = '2'
    this.$nextTick(() => {
      this.getBase()
      this.reflash = true
    })
  }
  created() {
    this.getProgramLanguage()
  }
}
