























































































































































































































































































import VueBase from '../../../VueBase'
import { Component, Prop } from 'vue-property-decorator'
import { formatTimestamp } from '@/utils/utils'
@Component({
  name: 'HookTable',
  filters: {
    formatTimestamp(date: number | any) {
      return formatTimestamp(date)
    },
  },
})
export default class HookTable extends VueBase {
  @Prop({ default: '0', type: String }) ruleType!: string
  @Prop({ default: 1, type: Number }) activeLanguage!: number
  @Prop({ default: '', type: String }) activeLanguageName!: string
  @Prop({
    default: function () {
      return true
    },
    type: Function,
  })
  getBase!: any

  keyword = ''
  hookTypeDialog = false
  hookDialog = false
  rule_type = ''
  hookType = {
    type: '1',
    name: '',
    short_name: '',
    enable: 1,
    language_id: this.activeLanguage,
  }
  types = []
  hook = {
    id: 0,
    strategy_id: '',
    language_id: this.activeLanguage,
    value: '',
    status: 1,
    type: '5',
  }
  relations = [
    { label: this.$t('views.hookPage.or'), value: '|' },
    { label: this.$t('views.hookPage.and'), value: '&' },
  ]
  origins = [
    { label: this.$t('views.hookPage.O'), value: 'O' },
    { label: this.$t('views.hookPage.R'), value: 'R' },
    { label: this.$t('views.hookPage.P'), value: 'P' },
  ]
  tableData = []
  pageSize = 20
  currentPage = 1
  total = 0

  multipleSelection = []
  handleSelectionChange(val: any) {
    this.multipleSelection = val
  }

  async changeStatusBatch(op: any) {
    let str = ''
    switch (op) {
      case 2:
        str = this.$t('views.hookPage.del') as string
        break
      case 1:
        str = this.$t('views.hookPage.on') as string
        break
      case 0:
        str = this.$t('views.hookPage.off') as string
        break
    }
    this.$confirm(
      `${this.$t('views.hookPage.changeOne')}${str}${this.$t(
        'views.hookPage.changeTwo'
      )}`,
      this.$t('views.hookPage.pop') as string,
      {
        confirmButtonText: this.$t('views.hookPage.enter') as string,
        cancelButtonText: this.$t('views.hookPage.clear') as string,
        type: 'warning',
      }
    )
      .then(async () => {
        if (this.multipleSelection.length === 0) {
          this.changeStatusAll(op)
          return
        }
        const ids = this.multipleSelection.map((item: any) => item.id)
        const { status, msg } = await this.services.setting.changeVulStatus({
          ids: ids,
          mode: 1,
          status: op,
        })
        if (status !== 201) {
          this.$message({
            type: 'error',
            message: msg,
            showClose: true,
          })
          return
        }
        this.$message({
          type: 'success',
          message: msg,
          showClose: true,
        })
        await this.getBase()
        await this.getTable()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  splitAndIn(str: string, key: string) {
    const arr = str.split(key)
    for (let i = 0; i < arr.length; i++) {
      if (i % 2 != 0) {
        arr.splice(i, 0, key)
        i++
      }
    }
    return arr
  }

  makeParamsArr(arr: any) {
    function fmtParams(params: string, symb: string) {
      const objArr: { relation: string; origin: string; param: string }[] = []
      if (params[0] === 'P') {
        const paramsStr = params.substring(1, params.length)
        const paramsArr = paramsStr.split(',')
        paramsArr.forEach((item) => {
          if (objArr.some((i) => i.origin === 'P')) {
            objArr.push({ relation: '&', origin: 'P', param: item })
          } else {
            objArr.push({ relation: symb, origin: 'P', param: item })
          }
        })
      } else {
        objArr.push({ relation: symb, origin: params, param: '' })
      }
      return objArr
    }
    function fmtParamsArr(arr: any) {
      const source = []
      if (arr.length == 1) {
        source.push(...fmtParams(arr[0], ''))
      } else {
        source.push(...fmtParams(arr[1], arr[0]))
      }
      return source
    }
    const source = []
    const params = []
    let pre = 0
    for (let k = 0; k < arr.length; k++) {
      if (arr[k] == '|' || arr[k] == '&') {
        source.push(arr.slice(pre, k))
        pre = k
      }
      if (k == arr.length - 1) {
        source.push(arr.slice(pre, arr.length))
      }
    }
    for (let i = 0; i < source.length; i++) {
      params.push(...fmtParamsArr(source[i]))
    }
    return params
  }

  async editRow(row: any) {
    this.hook.strategy_id = row.strategy_id
    this.hook.id = row.id
    this.hook.language_id = row.language_id
    this.hook.value = row.value
    this.hook.type = '5'
    this.hook.status = row.status
    this.hookDialog = true
  }

  async getTypes() {
    this.loadingStart()
    const { status, msg, data } = await this.services.setting.strategyList(
      true,
      {}
    )
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.types = data
  }

  addSource(arr: any) {
    arr.push({ relation: '', origin: '', param: '' })
  }
  deleteSource(arr: any, key: number) {
    arr.splice(key, 1)
    arr[0].relation = ''
  }

  changeOrigin(obj: any) {
    if (obj.origin !== 'P') {
      obj.param = ''
    }
  }
  async deleteRule(row: any) {
    this.loadingStart()
    const { status, msg } = await this.services.setting.ruleDelete({
      rule_id: row.id,
    })
    this.loadingDone()
    row.visible = false
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    await this.getTable()
  }

  async changeStatusAll(status: any) {
    this.loadingStart()
    const obj = await this.services.setting.changeVulStatus({
      mode: 2,
      status: status,
    })
    this.loadingDone()
    if (obj.status !== 201) {
      this.$message({
        showClose: true,
        message: obj.msg,
        type: 'error',
      })
      return
    }
    this.$message({
      showClose: true,
      message: obj.msg,
      type: 'success',
    })
    this.currentPage = 1
    await this.getBase()
    await this.getTable()
  }

  async changeStatus(row: any) {
    this.loadingStart()
    const obj = await this.services.setting.changeVulStatus({
      mode: 1,
      ids: [row.id],
      status: row.status,
    })
    this.loadingDone()
    if (obj.status !== 201) {
      this.$message({
        showClose: true,
        message: obj.msg,
        type: 'error',
      })
      return
    }
    await this.getBase()
    await this.getTable()
  }

  async deleteVuln(row: any) {
    this.loadingStart()
    const obj = await this.services.setting.delVulStatus({
      id: row.id,
    })
    this.loadingDone()
    if (obj.status !== 201) {
      this.$message({
        showClose: true,
        message: obj.msg,
        type: 'error',
      })
      return
    }
    await this.getBase()
    await this.getTable()
  }

  clearHookType() {
    this.hookType = {
      type: this.ruleType,
      name: '',
      language_id: this.activeLanguage,
      short_name: '',
      enable: 0,
    }
    this.hookTypeDialog = false
  }
  async enterHookType() {
    this.loadingStart()
    const { status, msg } = await this.services.setting.ruleTypeAdd(
      this.hookType
    )
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    await this.getTypes()
    this.clearHookType()
  }
  fmtType(type: string) {
    switch (type) {
      case '1':
        return this.$t('views.hookPage.spreadType') as string
      case '2':
        return this.$t('views.hookPage.contaminatedType') as string
      case '3':
        return this.$t('views.hookPage.filterType') as string
      case '4':
        return this.$t('views.hookPage.dangerType') as string
      case '5':
        return '主动验证'
    }
  }
  clearHook() {
    this.hook = {
      id: 0,
      strategy_id: '',
      language_id: this.activeLanguage,
      value: '',
      status: 1,
      type: '5',
    }
    this.hookDialog = false
  }
  fmtParams(arr: any) {
    let pStr = ''
    let str = ''
    arr.forEach((item: any) => {
      if (item.origin === 'P' && !pStr) {
        pStr += `${item.relation}${item.origin}${item.param}`
      } else if (item.origin === 'P' && pStr) {
        pStr += `,${item.param}`
      } else {
        str += `${item.relation}${item.origin}`
      }
    })
    return str + pStr
  }
  async enterHook() {
    if (this.hook.id) {
      this.loadingStart()
      const { status, msg } =
        await this.services.setting.edit_vul_recheck_payload({
          ...this.hook,
        })

      this.loadingDone()
      if (status !== 201) {
        this.$message({
          type: 'error',
          message: msg,
          showClose: true,
        })
        return
      }
      await this.getTable()
      this.clearHook()
    } else {
      this.loadingStart()
      const { status, msg } = await this.services.setting.vul_recheck_payload({
        ...this.hook,
      })

      this.loadingDone()
      if (status !== 201) {
        this.$message({
          type: 'error',
          message: msg,
          showClose: true,
        })
        return
      }
      await this.getBase()
      await this.getTable()
      this.clearHook()
    }
  }
  async changeRuleType() {
    this.currentPage = 1
    await this.getTable()
  }
  async getTable() {
    this.loadingStart()
    const { status, msg, data, page } =
      await this.services.setting.vulRecheckPayload({
        page: this.currentPage,
        page_size: this.pageSize,
        strategy_id: this.rule_type,
        language_id: this.activeLanguage,
        keyword: this.keyword,
      })
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.total = page.alltotal
    if (data.length === 0 && this.currentPage > 1) {
      this.currentPage--
      await this.getTable()
      return
    }
    this.tableData = data
  }
  handleSizeChange(val: number) {
    this.pageSize = val
    this.currentPage = 1
    this.getTable()
  }
  handleCurrentChange(val: number) {
    this.currentPage = val
    this.getTable()
  }
  created() {
    this.hookType.type = this.ruleType
    this.hook.type = this.ruleType
    this.getTable()
    this.getTypes()
  }
}
